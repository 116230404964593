body {
  margin: 0;
  font-family: MicrosoftYaHeiLight ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1vw;
  line-height: 1.4vw;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background:#051340 ;


}
html{
  background:#051340 ;
}
.App{
  background:#051340 ;
}
.App .bg{
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  background-color:#051340;
  background-image: url("./static/bg.png");
}

.App .bg .top{
  height: 14vw;
  width: 100vw;
  background:url("./static/top.png") no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: 0;
  left: 0;
}

.App .bg .bottom{
  height: 14vw;
  width: 100vw;
  background:url("./static/bt.png") no-repeat;
  background-size: 100% auto;
  position: absolute;
  background-position-y: bottom;
  bottom: 0;
  left: 0;
}

.App .bg .center11{
  height: calc(100vh - 9vw);
  width: 30vw;
  background:url("./static/l_bg.png") no-repeat;
  background-size: auto 100%;
  position: absolute;
  background-position:left center;
  top: 7.5vw;
  left: 0;
}


.App .bg .center12{
  height: calc(100vh - 9vw);
  width: 30vw;
  background:url("./static/r_bg.png") no-repeat;
  background-size: auto 100%;
  position: absolute;
  background-position:right center;
  top: 7.5vw;
  right: 0;
}

.App .bg .center2{
  height: 100vh;
  width: 100vw;
  bottom: 0;
  left: 0;
  background: radial-gradient(#051340,rgba(0,0,0,0) 60%);
}

.App .content{
  position: absolute;
  z-index: 2;
  left: 0; top: 10vw;
  margin: 0 80px;
  width: calc(100vw - 160px);
  height: calc(100vh - 10vw);
  color: #fff;
  font-size: 1vw;
}

.App .content .title{
  background-image: url("./static/title_bf.png");
  padding-left: 1.4vw;
  background-repeat: no-repeat;

  background-size: 1.2vw auto;
  background-position: left center;
  color: #16D7D6;
  font-size: 1.2vw;
  text-align: left;
  line-height: 2vw;
}


.App .content .user_pic{
  background-image: url("./static/face_bg.png");
  width: 5vw;
  background-size: 5vw 5vw;
  background-repeat: no-repeat;
  margin: auto;
}

.App .content .user_pic .d1{
  font-size: 1.2vw;
  font-family:Verdana;
  color: #3AFFE4;
}
.App .content .user_pic .d1.red{
  font-size: 1.2vw;
  font-family:Verdana;
  color: #FF9419;
}

.App .content .user_pic .d2{
  font-size: 1vw;
  font-family:Verdana;
}

.App .content .box{
  margin-top: 1.5vw;
  height: calc((100vh - 28vw)/2);
  display: flex;
  flex-direction: column;
}
.App .content .box .tp{
  font-family: "Microsoft YaHei UI";
  background: url("./static/box_tp.png") no-repeat;
  background-size: 100% auto;
  line-height: 1.6vw;
  height: 1.6vw;
  font-size: 1.2vw;
}

.App .content .box .ct{
  background: url("./static/box_center.png") repeat-y;
  background-size: 100% auto;
  flex: 1;
  overflow: hidden;
}

.App .content .box .bt{
  background: url("./static/box_bt.png") no-repeat bottom;
  background-size: 100% auto;
  height: .8vw;
}

*{
  animation-duration: .2s;
}

.App .content .scrollList{
  position: relative;
}


@keyframes run {
  from {

    stroke-dashoffset: 214;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes frdFan {
  from {

    stroke-dashoffset: 125.7;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes backFan {
  from {

    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 50;
  }
}

.warterfan{
  animation: run 30s linear infinite;
}

.frdFan{
  animation: frdFan 12s linear infinite;

}

.backFan{
  animation: backFan 6s linear infinite;
}
#amp{
  position: absolute;
  left: 0; top:0;
  z-index: 1;
}
#mapMask{
  width: 100vw;
  height: 100vh;
  background: url("./static/bg.png");
  mask:  radial-gradient(closest-corner at 50% 50%, rgba(0,0,0,0), rgba(0,0,0,0) 20%, #051340,  #051340);
  z-index: 3;
  position: absolute;
  left: 0; top:0;
}
