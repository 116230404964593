




.parent {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    overflow-y: hidden;/* 必须 */
    scrollbar-width: none;
    -ms-overflow-style: none;
    flex: 1;
}
.parent::-webkit-scrollbar {
    display: none;
}

/*设置的子盒子高度大于父盒子，产生溢出效果*/
.child {
    height: auto;
}
